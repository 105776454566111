.profile-selector-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;

  p {
    margin-left: 10px;
    margin-right: 10px;
  }
}

li > a.disabled {
  &:hover {
    color: #91a0b7 !important;
  }

  &:active {
    color: red !important;
  }
}

.profile-selector {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-group.profile-selector {
  display: flex;
  margin-bottom: 0;
  border-right: 1px solid #4b5769;
  border-left: 1px solid #4b5769;

  &__loading {
    display: flex;
    align-items: center;
    height: 65px;
  }

  label {
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
    color: white;
  }

  .react-select {
    display: flex;

    &:focus {
      border: none;
    }

    &__control {
      display: flex;
      color: white;
      width: 200px;
      border-bottom-width: 0;
      background-color: transparent;
      height: 65px;
      border-width: 0;

      &:hover {
        background-color: rgba(27, 39, 58, 0.8);
      }
    }

    &__input {
      color: white;
    }

    &__value-container {
      &:hover {
        cursor: text;
      }
    }

    &__indicators {
      &:hover {
        cursor: pointer;
      }
    }

    &__option {
      font-weight: bold;
    }

    &__sub-option {
      padding-left: 30px;
      font-weight: normal;
    }

    &__placeholder {
      color: #cacaca;
    }
  }
}
